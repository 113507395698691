import { createMuiTheme } from "@material-ui/core/styles"
import "./fonts/proxima-nova"

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiInput: {
      disableUnderline: true,
    },
  },
  palette: {
    green: {
      main: "#5B7F70",
      border: "#5B7F70",
      light: "#9AB8AC",
      support: "#EBF1EE",
      contrastText: "#fff",
      hoverContrastText: "#fff",
      disabled: "#C3C5C8",
    },
    invertedGreen: {
      main: "#FFF",
      border: "#FFF",
      light: "#9AB8AC",
      support: "#EBF1EE",
      contrastText: "#5B7F70",
      borderWidth: "1px",
      borderColor: "#5B7F70",
      hoverContrastText: "#5B7F70",
    },
    blue: {
      main: "#5D89B3",
      border: "#5D89B3",
      light: "#6199AE",
      support: "#EEF3F7",
      contrastText: "#fff",
      hoverContrastText: "#fff",
      disabled: "#C3C5C8",
    },
    black: {
      main: "#212221",
      border: "#212221",
      light: "#545759",
      support: "#8A8B8C",
      supportLight: "#C3C5C8",
      supportLightBackground: "#F5F5F5",
      contrastText: "#fff",
      hoverContrastText: "#fff",
      disabled: "#C3C5C8",
    },
    white: {
      main: "#FFF",
      border: "#FFF",
      light: "#545759",
      support: "#C3C5C8",
      supportLight: "#8A8B8C",
      contrastText: "#212221",
      hoverContrastText: "#FFF",
      disabled: "#C3C5C8",
    },
    transparent: {
      main: "transparent",
      support: "transparent",
      border: "transparent",
      contrastText: "#212221",
      hoverContrastText: "#212221",
    },
    red: {
      main: "#E20000",
      border: "#E20000",
      support: "#E20000",
      border: "#E20000",
      contrastText: "#E20000",
      hoverContrastText: "#E20000",
    },
    secondary: {
      main: "#212221",
      border: "#212221",
    },
    tertiary: {
      main: "#231C1D",
      border: "#231C1D",
      light: "#8A8B8C",
    },
    gray: {
      main: "#F5F5F5",
      border: "#F5F5F5",
      support: "#F3F3F3",
      contrastText: "#F3F3F3",
      hoverContrastText: "#F3F3F3",
    },
    payu: {
      main: "#B7D100",
      border: "#B7D100",
    },
  },

  //TYPOGRAPHY
  typography: {
    fontFamily: [
      "Proxima Nova",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),

    button: {
      fontSize: 13,
      letterSpacing: 1,
      fontWeight: "normal",
      textTransform: "uppercase",
    },

    h1: {
      fontWeight: 300,
      fontSize: 40,
      letterSpacing: 4,
      lineHeight: "48px",
      textTransform: "uppercase",
    },

    h2: {
      fontWeight: 300,
      fontSize: 40,
      lineHeight: "48px",
    },

    h3: {
      fontWeight: 300,
      fontSize: 36,
      lineHeight: "40px",
    },

    h4: {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: "36px",
    },

    h5: {
      fontWeight: "normal",
      fontSize: 20,
      lineHeight: "28px",
    },

    h6: {
      fontWeight: 600,
      fontSize: 13,
      letterSpacing: 1,
      lineHeight: "20px",
    },
    subtitle1: {
      fontSize: 20,
      lineHeight: "32px",
    },

    subtitle2: {
      fontWeight: 300,
      fontSize: 32,
      lineHeight: "40px",
    },

    body1: {
      fontSize: 16,
      lineHeight: "24px",
      "p&": {
        marginTop: "20px",
      },
    },

    body2: {
      fontSize: 14,
      lineHeight: "22px",
    },
    caption: {
      fontSize: 16,
      lineHeight: "28px",
    },
    overline: {
      fontSize: 16,
      lineHeight: "22px",
    },
  },

  //BREAKPOINTS
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 768,
      lg: 1024,
      xl: 1440,
    },

    margins: {
      xs: 0,
      sm: 2,
      md: 2,
      lg: 5,
      xl: 9,
    },

    gutters: {
      xs: 4,
      sm: 4,
      md: 4,
      lg: 4,
      xl: 6,
    },
  },
})

export default theme
