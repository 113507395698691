import React from "react"
import TopLayout from "./TopLayout"
import { NoSsr } from "@material-ui/core"

export const wrapRootElement = ({ element }) => {
  return (
    <NoSsr>
      <TopLayout>{element}</TopLayout>
    </NoSsr>
  )
}
